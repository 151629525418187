import network from '../network';

export default class Resident {

    static async getResidentsByEconomicZone(data) {
        let res = await network.get(`/resident/search`, {...data});
        if (res.isSuccess) {
            return res.result
        }
    }

    static async getResidentsByGroup(data) {
        return await network.get(`/plan`, {...data});
    }

    static async getResidentData(id) {
        let res = await network.get(`/resident/general`, {
            residentId: id
        });
        if (res.isSuccess) {
            return res.result
        }
    }

    static async uploadIndicators(data) {
        let res = await network.post('/resident/general', {...data}, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (res.isSuccess) {
            return res.result
        }
    }

    static async uploadStatus(data) {
        let res = await network.post('/resident/status', {...data}, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.result
    }

    static async getMainStatistics (data) {
        let res = await network.get('/employee', {...data});
        if (res.isSuccess) {
            return res.result
        }
    }

}