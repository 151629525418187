import {createStore} from 'vuex';
import axios from 'axios';
import api from '@/api';
import utils from '@/assets/js/utils';
import {useToast} from "vue-toastification";
import router from '@/router';
import network from "@/api/network";

const toast = useToast();

export default createStore({
    modules: {},
    state: {
        preloader: {
            main: 0,
            chat: 0,
            row: 0,
            block: 0,
            review: 0
        },
        navList: null,
        userData: null,
        forbiddenState: false,
        forbiddenData: null,
        statePostSupportForm: null,
        main: null,
        formData: null,
        formDetailData: null,
        modalData: null,
        chatData: null,
        isChangeChatData: false,
        searchedResidents: null,
        residentData: null,
        mainStatistics: null,
        reviewData: null,
        reviewBodyData: null,
        reviewStatisticData: null,
        searchReviewStatisticData: null,
        reviewStatisticFiltersData: null,
        reviewSearchOptionsData: null,
        reviewStatisticSearchOptionsData: null,
        canSendForm: true,
        isSearchRender: true,
        merData: null,
        merDetailData: null,
        merModalData: null,
        minecData: null,
        minecDetailData: null,
        minecModalData: null,
        gisipDetailData: null,
        errorList: null,
        reviewModalData: null,
        gisipModalData: null,
        reviewDetailModalData: null,
        downloadReportData: null,
        filterParams: null,
        focusCell: null
    },
    getters: {
        //====preloader
        PRELOADER(state) {
            return state.preloader;
        },
        //=====user
        NAV_LIST(state) {
            return state.navList;
        },
        GET_USER(state) {
            return state.userData;
        },
        GET_FORBIDDEN_STATE(state) {
            return state.forbiddenState;
        },
        GET_FORBIDDEN_DATA(state) {
            return state.forbiddenData;
        },
        //=====main
        GET_MAIN(state) {
            return state.main;
        },
        GET_MAIN_DEADLINE(state) {
            return state.main?.days;
        },
        //=====form
        GET_STATE_POST_SUPPORT_FORM(state) {
            return state.statePostSupportForm;
        },
        GET_FORM_DATA(state) {
            return state.formData;
        },
        GET_FORM_DETAIL_DATA(state) {
            return state.formDetailData;
        },
        GET_ERROR_LIST(state) {
            return state.errorList;
        },
        //=====comment
        GET_MODAL_DATA(state) {
            return state.modalData;
        },
        GET_CHAT_DATA(state) {
            return state.chatData;
        },
        GET_IS_CHANGE_CHAT_DATA(state) {
            return state.isChangeChatData;
        },
        //=====residents
        GET_SEARCHED_RESIDENTS(state) {
            return state.searchedResidents;
        },

        GET_RESIDENT_DATA(state) {
            return state.residentData
        },
        GET_MAIN_STATISTICS(state) {
            return state.mainStatistics;
        },
        //=====review
        GET_REVIEW_DATA(state) {
            return state.reviewData;
        },
        GET_REVIEW_STATISTIC_DATA(state) {
            return state.reviewStatisticData;
        },
        GET_REVIEW_STATISTIC_FILTERS_DATA(state) {
            return state.reviewStatisticFiltersData;
        },
        GET_SEARCH_REVIEW_STATISTIC_OPTIONS_DATA(state) {
            return state.reviewStatisticSearchOptionsData;
        },
        GET_SEARCH_REVIEW_STATISTIC_DATA(state) {
            return state.searchReviewStatisticData;
        },
        GET_SEARCH_REVIEW_OPTIONS_DATA(state) {
            return state.reviewSearchOptionsData;
        },
        CAN_SEND_FORM(state) {
            return !state.canSendForm;
        },
        IS_SEARCH_RENDER(state) {
            return state.isSearchRender;
        },
        GET_REVIEW_MODAL_DATA(state) {
            return state.reviewModalData;
        },
        GET_GISIP_MODAL_DATA(state) {
            return state.gisipModalData;
        },
        GET_REVIEW_DETAIL_MODAL_DATA(state) {
            return state.reviewDetailModalData;
        },
        GET_IS_FOCUS_CELL(state) {
            return state.focusCell;
        },
        //=====mer
        GET_MER_DATA(state) {
            return state.merData;
        },
        GET_MER_DETAIL_DATA(state) {
            return state.merDetailData;
        },
        GET_MER_MODAL_DATA(state) {
            return state.merModalData;
        },
        GET_FILTER_PARAMS(state) {
            return state.filterParams;
        },
        //===minec
        GET_MINEC_DATA(state) {
            return state.minecData;
        },
        GET_MINEC_DETAIL_DATA(state) {
            return state.minecDetailData;
        },
        GET_MINEC_MODAL_DATA(state) {
            return state.minecModalData;
        },
        // gisip
        GET_GISIP_DETAIL_DATA(state) {
            return state.gisipDetailData;
        },
    },
    mutations: {
        //====preloader
        SET_PRELOADER_INCR(state, code) {
            state.preloader[code]++;
        },
        SET_PRELOADER_DECR(state, code) {
            setTimeout(() => {
                state.preloader[code]--;
            }, 500)
        },
        //=====user
        SET_USER(state, data) {
            if (data) {
                state.userData = data
            }
            if (data && data.token) {
                axios.defaults.headers.common['Authorization'] = data.token;
            }
        },
        SET_NAV_LIST(state, data) {
            if (data.menu) {
                state.navList = data.menu;
            }
            if (data.btnMenu) {
                state.navList = {
                    type: 'multiple',
                    menu: data.btnMenu,
                }
            }
        },
        RESET_USER(state) {
            state.userData = null
            axios.defaults.headers.common['Authorization'] = ''
        },
        SET_FORBIDDEN_STATE(state, forbiddenState) {
            state.forbiddenState = forbiddenState
        },
        SET_FORBIDDEN_DATA(state, forbiddenData) {
            state.forbiddenData = forbiddenData
        },
        SET_STATE_POST_SUPPORT_FORM(state, boolean) {
            state.statePostSupportForm = boolean
        },
        //=====main
        SET_MAIN(state, data) {
            state.main = data
        },
        //=====form
        SET_FORM_DATA(state, data) {
            state.formData = data
        },
        SET_FORM_DETAIL_DATA(state, data) {
            if (state.formDetailData) {
                if (state.formDetailData[0].code === "SCID_REPORT_FORM_7" && data[0].code === "SCID_REPORT_FORM_7") {
                    for (let i = 0; i < state.formDetailData[0].cards.length; i++) {
                        let entityId = state.formDetailData[0].cards[i].entityId;
                        for (let j = 0; j < data[0].cards.length; j++) {
                            if (data[0].cards[j].entityId === entityId) {
                                data[0].cards[j] = state.formDetailData[0].cards[i];
                            }
                        }
                    }
                }
            }
            state.formDetailData = data;
            this.commit('SET_ERROR_LIST')
        },
        UPDATE_DATA_FORM(state, data) {
            if (data && data.length) {
                let detailData = state.formDetailData
                data.forEach(
                    elem => {
                        elem.cards.forEach(
                            card => {
                                detailData.forEach(
                                    el => el.cards.forEach(
                                        detailCard => {
                                            if ((detailCard.entityId == card.entityId) && (detailCard.id == card.id)) {
                                                detailCard.fields = card.fields
                                                detailCard.isDateChecked = card.isDateChecked
                                                if (detailCard.items) {
                                                    detailCard.items = card.items
                                                }
                                            }
                                        }
                                    )
                                )
                            }
                        )
                    }
                )
                state.formDetailData = [...detailData];
                this.commit('SET_ERROR_LIST')
            }
        },
        SET_STATE_CHECK_FORM(state, data) {
            let resArr = [];
            data.forEach(
                elem => {
                    elem.cards.forEach(
                        card => {
                            if (card.fields && card.fields.lenght) {
                                let res = card.fields.some(field => {
                                    return field.info.isError === true
                                })
                                resArr.push(res)
                            }
                        }
                    )
                }
            )
            state.canSendForm = resArr.some(el => {
                return el === true
            })
        },
        SET_INPUTS_ERRORS(state, dataErr) {
            dataErr.forEach(
                errElem => {
                    state.formDetailData.forEach(
                        detailData => {
                            detailData.cards.forEach(card => {
                                    card.fields.forEach(field => {
                                        if (field.code == errElem) {
                                            field.info.isError = true
                                        }
                                    })
                                }
                            )
                        }
                    )
                }
            )
        },
        SET_ERROR_LIST(state) {
            let detailData = state.formDetailData,
                errArr = [];
            detailData.forEach(el => {
                if (el.cards && el.cards.length) {
                }
                el.cards.forEach(card => {
                    if (card.fields && card.fields.length) {
                        let isErr = card.fields.some(field => {
                            return field.info.isError === true
                        })

                        if (isErr) {
                            errArr.push({
                                code: card.code,
                                name: card.name
                            })
                        }
                    }
                })
            })
            if (errArr.length) {
                state.errorList = errArr;
            } else {
                state.errorList = null;
            }
        },
        //=====comment
        SET_MODAL_DATA(state, data) {
            state.modalData = data;
        },
        SET_CHAT_DATA(state, data) {
            state.chatData = data;
        },
        SET_IS_CHANGE_CHAT_DATA (state, data) {
            state.isChangeChatData = data;
        },
        //=====residents
        SET_SEARCHED_RESIDENTS(state, data) {
            state.searchedResidents = data;
        },

        SET_RESIDENT_DATA(state, data) {
            state.residentData = data;
        },
        SET_MAIN_STATISTICS(state, data) {
            let chartData = data.statistic.items.find(item => item.type === 'chart')
            state.mainStatistics = chartData.chart;
        },

        SET_GISIP_DATA(state, data) {
            let forms = [];
            for (let objKey in data.forms) {
                let obj = {};
                let rowId,
                    operations,
                    rowCode = null;
                obj.values = [];
                let formObj = JSON.parse(JSON.stringify(data.forms[objKey]));

                for (let key in formObj) {
                    let findDataBody = data.body.find(elemBody => elemBody.code == key)
                    if (key == 'id') {
                        obj.id = formObj[key];
                        rowId = formObj[key];
                        delete formObj[key];
                    } else if (key == 'code') {
                        obj.code = formObj[key];
                        rowCode = formObj[key];
                        delete formObj[key];
                    } else if (key == 'operations') {
                        operations = formObj[key];
                        delete formObj[key];
                    } else if (key == 'isDisabled') {
                        obj.isDisabled = formObj[key];
                        delete formObj[key];
                    } else if (findDataBody) {
                        let value = '';
                        if (formObj[key]) {
                            value = formObj[key]
                        }
                        formObj[key] = JSON.parse(JSON.stringify(findDataBody));
                        if (rowId) {
                            formObj[key].rowId = rowId;
                        }
                        if (rowCode) {
                            formObj[key].rowCode = rowCode;
                        }
                        if (operations) {
                            formObj[key].operations = operations;
                        }

                        formObj[key].value = value
                    } else {
                        delete formObj[key];
                    }
                }

                if (operations) {
                    for (let op in operations) {
                        for (let key in formObj) {
                            if (op == key) {
                                formObj[key].operations = operations[op]
                            }
                        }
                    }
                }
                if (obj.isDisabled && obj.isDisabled.length) {
                    obj.isDisabled.forEach(el => {
                        if (formObj[el]) {
                            formObj[el].isDisabled = true
                        }
                    })
                }

                obj.values.push((formObj))
                forms.push(obj)
            }

            state.gisipDetailData = data;
            state.gisipDetailData.forms = forms;
        },

        //=====review
        SET_REVIEW_DATA(state, data) {
            state.reviewData = data;
            state.reviewBodyData = data.forms
            if (data.isSearchRender || data.isSearchRender == false) {
                state.isSearchRender = data.isSearchRender;
            }
        },
        SET_DOWNLOAD_REPORT_DATA(state, data) {
            state.downloadReportData = data;
        },
        SET_REVIEW_STATISTIC_DATA(state, data) {
            state.reviewStatisticData = data;
            state.reviewBodyData = data.forms
            if (data.isSearchRender || data.isSearchRender == false) {
                state.isSearchRender = data.isSearchRender;
            }
        },
        SET_REVIEW_STATISTIC_FILTERS_DATA(state, data) {
            state.reviewStatisticFiltersData = data;
        },

        SET_FILTER_REVIEW_DATA(state, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let filterArr = [];
            let formsDb = JSON.parse(JSON.stringify(state.reviewBodyData));
            data.forEach(el => {
                formsDb.forEach(formsEl => {
                    if (formsEl.resident && formsEl.resident.id && formsEl.resident.id == el) {
                        filterArr.push(formsEl);
                    }
                })
            })
            state.reviewData.forms = filterArr;
            this.dispatch('setPreloaderDecr', 'main');
        },
        SET_FILTER_REVIEW_STATISTIC_DATA(state, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let filterArr = [];
            let formsDb = JSON.parse(JSON.stringify(state.reviewBodyData));
            if (Object.entries(formsDb).length === 0) {
                filterArr = []
            } else {
                data.forEach(el => {
                    formsDb.forEach(formsEl => {
                        if (formsEl.resident && formsEl.resident.id && formsEl.resident.id == el) {
                            filterArr.push(formsEl);
                        }
                    })
                })
            }
            state.reviewStatisticData.forms = filterArr;
            this.dispatch('setPreloaderDecr', 'main');
        },
        RESET_STATE_CHECK_FORM(state) {
            state.canSendForm = true;
        },

        RESET_FILTER_REVIEW_DATA(state, code) {
            this.dispatch('setPreloaderIncr', 'main');
            if (code == 'statistic') {
                state.reviewStatisticData.forms = JSON.parse(JSON.stringify(state.reviewBodyData));
                state.searchReviewStatisticData = [];
            } else {
                state.reviewData.forms = JSON.parse(JSON.stringify(state.reviewBodyData));
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        SET_SEARCH_REVIEW_STATISTIC_DATA(state, data) {
            state.searchReviewStatisticData = data
        },
        SET_SEARCH_REVIEW_OPTIONS_DATA(state, data) {
            let bodyData = [];

            for (let key in data.forms) {
                if (data.forms[key].resident) {
                    let item = {
                        value: data.forms[key].resident.id,
                        title: data.forms[key].resident.name
                    }
                    bodyData.push(item)
                }
            }
            state.reviewSearchOptionsData = bodyData;
        },
        SET_SEARCH_REVIEW_STATISTIC_OPTIONS_DATA(state, data) {
            let bodyData = [];

            for (let key in data.forms) {
                if (data.forms[key].resident) {
                    let item = {
                        value: data.forms[key].resident.id,
                        title: data.forms[key].resident.name
                    }
                    bodyData.push(item)
                }
            }
            state.reviewStatisticSearchOptionsData = bodyData;
        },
        SET_REVIEW_MODAL_DATA(state, data) {
            state.reviewModalData = data;
        },
        SET_GISIP_MODAL_DATA(state, data) {
            state.gisipModalData = data;
        },
        SET_REVIEW_DETAIL_MODAL_DATA(state, data) {
            state.reviewDetailModalData = data;
        },
        //======mer
        SET_MER_DATA(state, data) {
            state.merData = data
        },
        SET_MER_MODAL_DATA(state, data) {
            state.merModalData = data
        },
        SET_MER_DETAIL_DATA(state, data) {
            let forms = [];
            for (let objKey in data.forms) {
                let obj = {};
                let rowId,
                    operations,
                    rowCode = null;
                obj.values = [];
                let formObj = JSON.parse(JSON.stringify(data.forms[objKey]));

                for (let key in formObj) {
                    let findDataBody = data.body.find(elemBody => elemBody.code == key)
                    if (key == 'id') {
                        obj.id = formObj[key];
                        rowId = formObj[key];
                        delete formObj[key];
                    } else if (key == 'code') {
                        obj.code = formObj[key];
                        rowCode = formObj[key];
                        delete formObj[key];
                    } else if (key == 'operations') {
                        operations = formObj[key];
                        delete formObj[key];
                    } else if (key == 'isDisabled') {
                        obj.isDisabled = formObj[key];
                        delete formObj[key];
                    } else if (findDataBody) {
                        let value = '';
                        if (formObj[key]) {
                            value = formObj[key]
                        }
                        formObj[key] = JSON.parse(JSON.stringify(findDataBody));
                        if (rowId) {
                            formObj[key].rowId = rowId;
                        }
                        if (rowCode) {
                            formObj[key].rowCode = rowCode;
                        }
                        if (operations) {
                            formObj[key].operations = operations;
                        }

                        formObj[key].value = value
                    } else {
                        delete formObj[key];
                    }
                }

                if (operations) {
                    for (let op in operations) {
                        for (let key in formObj) {
                            if (op == key) {
                                formObj[key].operations = operations[op]
                            }
                        }
                    }
                }
                if (obj.isDisabled && obj.isDisabled.length) {
                    obj.isDisabled.forEach(el => {
                        if (formObj[el]) {
                            formObj[el].isDisabled = true
                        }
                    })
                }

                obj.values.push((formObj))
                forms.push(obj)
            }
            state.merDetailData = data;
            state.merDetailData.forms = forms;
        },
        SET_FILTER_PARAMS(state, data) {
            state.filterParams = data;
        },
        SET_IS_FOCUS_CELL(state, data) {
            state.focusCell = data
        },
        //===minec
        SET_MINEC_DATA(state, data) {
            state.minecData = data
        },
        SET_MINEC_DETAIL_DATA(state, data) {
            let forms = [];
            for (let objKey in data.forms) {
                let obj = {};
                let rowId,
                    operations,
                    rowCode = null;
                obj.values = [];
                let formObj = JSON.parse(JSON.stringify(data.forms[objKey]));

                for (let key in formObj) {
                    let findDataBody = data.body.find(elemBody => elemBody.code == key)
                    if (key == 'id') {
                        obj.id = formObj[key];
                        rowId = formObj[key];
                        delete formObj[key];
                    } else if (key == 'code') {
                        obj.code = formObj[key];
                        rowCode = formObj[key];
                        delete formObj[key];
                    } else if (key == 'operations') {
                        operations = formObj[key];
                        delete formObj[key];
                    } else if (key == 'isDisabled') {
                        obj.isDisabled = formObj[key];
                        delete formObj[key];
                    } else if (findDataBody) {
                        let value = '';
                        if (formObj[key]) {
                            value = formObj[key]
                        }
                        formObj[key] = JSON.parse(JSON.stringify(findDataBody));
                        if (rowId) {
                            formObj[key].rowId = rowId;
                        }
                        if (rowCode) {
                            formObj[key].rowCode = rowCode;
                        }
                        if (operations) {
                            formObj[key].operations = operations;
                        }

                        formObj[key].value = value
                    } else {
                        delete formObj[key];
                    }
                }

                if (operations) {
                    for (let op in operations) {
                        for (let key in formObj) {
                            if (op == key) {
                                formObj[key].operations = operations[op]
                            }
                        }
                    }
                }
                if (obj.isDisabled && obj.isDisabled.length) {
                    obj.isDisabled.forEach(el => {
                        if (formObj[el]) {
                            formObj[el].isDisabled = true
                        }
                    })
                }

                obj.values.push((formObj))
                forms.push(obj)
            }
            state.minecDetailData = data;
            state.minecDetailData.forms = forms;
        },

        SET_MINEC_MODAL_DATA(state, data) {
            state.minecModalData = data
        },
    },
    actions: {
        //====preloader
        setPreloaderIncr(context, code) {
            context.commit('SET_PRELOADER_INCR', code);
        },
        setPreloaderDecr(context, code) {
            context.commit('SET_PRELOADER_DECR', code);
        },
        //=====user
        async fetchUser(context, hash) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.user.getUser(hash)
            if (res.status && res.result.user.role) {
                localStorage.setItem('user', JSON.stringify(res.result))
                context.commit('SET_USER', res.result)
                context.commit('SET_NAV_LIST', res.result)
                this.dispatch('getMain', res.result.user.role)
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        setUser(context, dataUser) {
            this.dispatch('setPreloaderIncr', 'main');
            context.commit('SET_USER', dataUser)
            context.commit('SET_NAV_LIST', dataUser)
            this.dispatch('setPreloaderDecr', 'main');
        },
        resetAuthorization(context, data) {
            localStorage.removeItem('user')
            context.commit('SET_FORBIDDEN_DATA', data)
            context.commit('SET_FORBIDDEN_STATE', true)
            context.commit('RESET_USER')
        },
        redirectForbidden(context, data) {
            context.commit('SET_FORBIDDEN_DATA', data)
            context.commit('SET_FORBIDDEN_STATE', true)
        },
        //=====main
        async getMain(context, role) {
            this.dispatch('setPreloaderIncr', 'main');
            let res;
            if (role === 'resident') {
                const resMain = await api.main.getMainResident(role);
                const resGeneral = await api.main.getGeneralResident();
                res = {...resMain, result: {...resMain.result, ...resGeneral.result}}
            } else {
                res = await api.main.getMain(role)
            }
            if (res.status) {
                context.commit('SET_MAIN', res.result)
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        //=====form
        async getForm(context, {formId, data}) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.form.getForm(formId, data)
            if (res) {
                context.commit('SET_FORM_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getFormDetail(context, route) {
            context.dispatch('setPreloaderIncr', 'main');
            let res;
            if (route.isTenant) {
                res = await api.tenant.getFormDetail(route, null);
            } else {
                res = await api.form.getFormDetail(route, null);
            }
            if (res) {
                context.commit('SET_FORM_DETAIL_DATA', res);
            }
            context.dispatch('setPreloaderDecr', 'main');
            return res;
        },
        async addFormBlockAndGetId(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            const res = await context.dispatch('addFormBlock', payload);
            context.dispatch('setPreloaderDecr', 'main');
            return res.result.id;
        },
        async addFormBlockAndGetIdTenant(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            let res;
            if (payload.data && payload.data.code === "SCID_REPORT_RESIDENT_REPORT_FORM_7_INTELLECTUAL_ACTIVITY") {
                res = await context.dispatch('addFormBlockAndGetId', payload);
            } else {
                res = await context.dispatch('addFormBlockTenant', payload);
            }
            context.dispatch('setPreloaderDecr', 'main');
            return res ? res : null;
        },
        async deleteFormBlock(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            await context.dispatch('delFormBlock', payload);
            context.dispatch('setPreloaderDecr', 'main');
        },

        async deleteFormBlockTenant(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            await context.dispatch('delFormBlockTenant', payload);
            context.dispatch('setPreloaderDecr', 'main');
        },

        async postSupportForm(context, payload) {
            context.commit('SET_STATE_POST_SUPPORT_FORM', null)
            let res = await api.user.postSupportForm(payload)
            if (res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
                context.commit('SET_STATE_POST_SUPPORT_FORM', false)
            }
            if (res.status) {
                context.commit('SET_STATE_POST_SUPPORT_FORM', true)
                toast.success('Данные успешно отправлены');
            }
        },
        async postForm(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = null;

            if (payload.route.isTenant) {
                res = await api.tenant.postForm(payload.route, payload.data)
            } else {
                res = await api.form.postForm(payload.route, payload.data)
            }
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
                // обрабатываем так же как и на предварительной проверке
                context.commit('UPDATE_DATA_FORM', res.result)
                //// обрабатываем  по первому варианту
                // for (let key in res.result) {
                //     context.commit('SET_INPUTS_ERRORS', res.result[key])
                // }
                // this.dispatch('getFormDetail', payload.route)
            }
            if (res && res.status) {
                if (res.toastMessage) {
                    toast.success(res.toastMessage);
                }

                router.replace({
                    name: 'form',
                    params: {
                        formId: payload.route.formId
                    }
                })
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async saveFormFile (context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.form.saveFile(payload)
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            this.dispatch('setPreloaderDecr', 'main');

            if (res && res.status) {
                return res.result
            }
        },
        async addFormBlock(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            let res = await api.form.addForm(payload.route);
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err));
            }
            context.dispatch('setPreloaderDecr', 'main');
            return res;
        },
        async addFormBlockTenant(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            let res = await api.form.addFormTenant(payload.route, payload.data);
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err));
            }
            context.dispatch('setPreloaderDecr', 'main');
            return res;
        },
        async delFormBlock(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            let res = await api.form.delForm(payload.route, payload.data);
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err));
            }
            context.dispatch('setPreloaderDecr', 'main');
            return res;
        },
        async delFormBlockTenant(context, payload) {
            context.dispatch('setPreloaderIncr', 'main');
            let res = await api.form.delFormTenant(payload.route, payload.data);
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err));
            }
            context.dispatch('setPreloaderDecr', 'main');
            return res;
        },
        async checkBlockForm(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.tenant.checkBlockForm(payload.isTenant, payload.route, payload.data)
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
                context.commit('UPDATE_DATA_FORM', res.result)
                //context.commit('SET_STATE_CHECK_FORM', res.result)
            }
            if (res && res.status) {
                if (res.toastMessage) {
                    toast.success(res.toastMessage);
                }
                context.commit('UPDATE_DATA_FORM', res.result)
                context.commit('SET_STATE_CHECK_FORM', res.result)
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        //=====comment
        async getComment(context, data) {
            this.dispatch('setPreloaderIncr', 'chat');
            let res = await api.comment.getComment(data);
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.result) {
                context.commit('SET_CHAT_DATA', res.result)
            }
            this.dispatch('setPreloaderDecr', 'chat');
        },
        async postComment(context, data) {
            this.dispatch('setPreloaderIncr', 'chat');
            let res = await api.comment.postComment(data);
            if (res && res.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status) {
                context.commit('SET_IS_CHANGE_CHAT_DATA', true)
                this.dispatch('getComment', data)
            }
            this.dispatch('setPreloaderDecr', 'chat');
        },
        //=====resident
        // поиск по резиденту ОЭС
        async getResidentsByEconomicZone(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.resident.getResidentsByEconomicZone(data);
            if (res) {
                context.commit('SET_SEARCHED_RESIDENTS', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        // поиск по групповой фильтрации
        async getResidentsByGroup(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            context.commit('SET_RESIDENT_DATA', null);
            let res = await api.resident.getResidentsByGroup(data);
            if (res.isSuccess) {
                context.commit('SET_RESIDENT_DATA', res.result);
            } else {
                context.commit('SET_RESIDENT_DATA', {errors: res.error});
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async getResidentData(context, id) {
            this.dispatch('setPreloaderIncr', 'main');
            context.commit('SET_RESIDENT_DATA', null);
            let res = await api.resident.getResidentData(id);
            if (res) {
                context.commit('SET_RESIDENT_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async uploadFile(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.resident.uploadIndicators(data);
            if (res?.hasErrors) {
                let data = {
                    code: 'errors',
                    errors: res.errors
                }
                context.commit('SET_MODAL_DATA', data)
            } else {
                toast.success('Плановые показатели успешно отправлены');
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async uploadStatus(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.resident.uploadStatus(data);
            if (res) {
                toast.error(res);
            } else {
                toast.success('Статусы успешно отправлены');
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async getMainStatistics(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.resident.getMainStatistics(data);
            if (res) {
                context.commit('SET_MAIN_STATISTICS', res);
                context.commit('SET_MAIN', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async getResidentReports(context, {page}) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.reports.getReports(page);
            if (res) {
                context.commit('SET_MAIN', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async postResidentReports(context) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.reports.addReport()
            this.dispatch('setPreloaderDecr', 'main');
        },
        //=====review
        async getReview(context, {formId, data}) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.review.getReview(formId, data);
            if (res) {
                context.commit('SET_REVIEW_DATA', res);
                context.commit('SET_SEARCH_REVIEW_OPTIONS_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getReviewDetail(context, route) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.review.getReviewDetail(route);
            if (res) {
                context.commit('SET_REVIEW_DATA', res);
                context.commit('SET_SEARCH_REVIEW_OPTIONS_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getReviewStatistics({commit, getters}, route) {
            this.dispatch('setPreloaderIncr', 'main');
            let idResident = getters.GET_SEARCH_REVIEW_STATISTIC_DATA;
            let res = await api.review.getReviewStatistics(route);
            if (res) {
                if (idResident && idResident.length > 0) {
                    let foundResident = res.forms.find(el => el.resident.id === idResident[0])
                    let resSummary;
                    if (foundResident) {
                        resSummary = {...res, forms: [foundResident]}
                    } else {
                        resSummary = resSummary = {...res, forms: {}}
                    }
                    commit('SET_REVIEW_STATISTIC_DATA', resSummary);
                } else {
                    commit('SET_REVIEW_STATISTIC_DATA', res);
                    commit('SET_SEARCH_REVIEW_STATISTIC_OPTIONS_DATA', res);
                }
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getReviewStatisticsFilters(context) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.review.getReviewStatisticsFilters();
            if (res) {
                context.commit('SET_REVIEW_STATISTIC_FILTERS_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getReviewStatisticsFilter({commit, getters}, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.review.getReviewStatisticsFilter(payload);
            let idResident = getters.GET_SEARCH_REVIEW_STATISTIC_DATA;
            if (res) {
                if (idResident && idResident.length > 0) {
                    let foundResident = res.forms.find(el => el.resident.id === idResident[0])
                    let resSummary;
                    if (foundResident) {
                        resSummary = {...res, forms: [foundResident]}
                    } else {
                        resSummary = {...res, forms: {}}
                    }
                    commit('SET_REVIEW_STATISTIC_DATA', resSummary);
                } else {
                    commit('SET_REVIEW_STATISTIC_DATA', res);
                }
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async filterReviewData(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            if (data) {
                context.commit('SET_FILTER_REVIEW_DATA', data);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async filterReviewStatisticData(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            if (data) {
                context.commit('SET_FILTER_REVIEW_STATISTIC_DATA', data);
                context.commit('SET_SEARCH_REVIEW_STATISTIC_DATA', data);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async resetStateCheckForm(context) {
            context.commit('RESET_STATE_CHECK_FORM');
        },
        async resetFilterReviewData(context) {
            this.dispatch('setPreloaderIncr', 'main');
            setTimeout(() => context.commit('RESET_FILTER_REVIEW_DATA'), 200)
            this.dispatch('setPreloaderDecr', 'main');
        },
        async resetFilterReviewStatisticData(context) {
            this.dispatch('setPreloaderIncr', 'main');
            setTimeout(() => context.commit('RESET_FILTER_REVIEW_DATA', 'statistic'), 200)
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getReviewModal(context) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getReviewModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_REVIEW_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getGisipIPModal(context) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getGisipIPModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_REVIEW_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getGisipTechnoparkModal(context) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getGisipTechnoparkModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_REVIEW_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getGisipModal(context, payload) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getGisipModal(payload);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_GISIP_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getMerModal(context) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.mer.getMerModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_MER_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },

        async getMinecModal(context) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.minec.getMinecModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_MINEC_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },

        async getTenantModal(context) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getTenantModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_REVIEW_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getLinkToReports(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getLinkToReports(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                window.open(res.link, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getDetailReviewModal(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getDetailReviewModal();
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && !res?.error) {
                context.commit('SET_REVIEW_DETAIL_MODAL_DATA', res)
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getGeneralResidentReview(context, data) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.review.getGeneralResidentReview(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.downloadUrl) {
                window.open(res.result.downloadUrl, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getGisipIPDownload(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getGisipIPDownload(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.link) {
                window.open(res.result.link	, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async getGisipTechnoparkDownload(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.getGisipTechnoparkDownload(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.link) {
                window.open(res.result.link	, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async postReviewModal(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.postReviewModalData(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.downloadUrl) {
                window.open(res.result.downloadUrl, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        async postReviewTransfer(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.review.postReviewTransferData(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status) {
                toast.success("Данные успешно отправлены");
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        // gisip
        async loadGisipTableData(context, {type, formId, year, data}) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.gisip.getTableData(type, formId, year, data);
            if (res) {
                context.commit('SET_GISIP_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async updGisip(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.gisip.updateTableData(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async acceptGisip(context, name) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.gisip.acceptTableData(name);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async downloadGisipModal(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.gisip.downloadReport(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.link) {
                window.open(res.result.link, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
        //=====mer
        async getMer(context, {formId, page, data}) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.mer.getMer(formId, page, data);
            if (res) {
                context.commit('SET_MER_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async getMerDetail(context, route) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.mer.getMerDetail(route);
            if (res) {
                context.commit('SET_MER_DETAIL_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async updMer(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.mer.updMer(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },
        async acceptMer(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.mer.acceptMer(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async addReportMer(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.mer.addReportMer(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async postMerModal(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.mer.postMerModalData(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.downloadUrl) {
                window.open(res.result.downloadUrl, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },

        //=====minec
        async getMinec(context, {formId, page, data}) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.minec.getMinec(formId, page, data);
            if (res) {
                context.commit('SET_MINEC_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async getMinecDetail(context, route) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.minec.getMinecDetail(route);
            if (res) {
                context.commit('SET_MINEC_DETAIL_DATA', res);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async updMinec(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.minec.updMinec(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async acceptMinec(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.minec.acceptMinec(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async addReportMinec(context, payload) {
            this.dispatch('setPreloaderIncr', 'main');
            let res = await api.minec.addReportMinec(payload);
            if (res && res.toastMessage) {
                toast.success(res.toastMessage);
            }
            this.dispatch('setPreloaderDecr', 'main');
        },

        async postMinecModal(context, data) {
            this.dispatch('setPreloaderIncr', 'review');
            let res = await api.minec.postMinecModalData(data);
            if (res?.error && utils.isArray(res.error.value)) {
                res.error.value.forEach(err => toast.error(err))
            }
            if (res && res.status && res.result.downloadUrl) {
                window.open(res.result.downloadUrl, '_blank');
            }
            this.dispatch('setPreloaderDecr', 'review');
        },
    },
})
